import Debugger from "../util/Debugger";

class Search {
  constructor() {
    this.$target = $('.js-seach-target');
    this.$remover = $('.js-search-remove');
  }
  init() {
    if (this.$target.length <= 0) return;
    Debugger.log(this.$remover.length);
    this.$remover.on('click', () =>
      this.onClick()
    );
  }
  onClick() {
    this.removeText();
  }

  removeText() {
    this.$target.val('');
  }
}


export default new Search;
