import Debugger from '../util/Debugger';
import Screen from '../util/Screen';

class Accordion {
  constructor($trigger, $area, $reference, onlySP) {
    // Debugger.log('---Accordion constructor---');

    this.isActive = $trigger.length > 0 ? true : false;
    if (!this.isActive) return;

    this.onlySP = onlySP ? onlySP : false; //trueの場合のみレスポンシブ処理が入る

    this.BREAK_POINT = 768;
    this.isPC = window.innerWidth >= this.BREAK_POINT;
    this.isPrevPC;

    this.$trigger = $trigger;
    this.$area = $area;
    this.$reference = $reference;
    this.isOpen = false;
    this.ani = 'height 0.3s ease';

    this.height = 0;

    this.initialize();
  }

  initialize() {
    if (!this.onlySP) {
      this.add();
    } else if (!this.isPC) {
      this.add();
    }
    Screen.addResizeObject(this);
  }

  add() {
    this.isOpen = false;
    this.addEventListener();
  }

  remove() {
    this.removeEventListener();
    this.isOpen = true;
    this.$area.attr('style', '');
    this.setAnimation(0);
  }

  /*
   * イベントを購読
   */
  addEventListener() {
    Debugger.log('---Accordion addEventListener---');
    this.toggle();
    this.setAnimation(this.ani);

    this.$trigger.on('click', () => {
      this.toggle();
    });
  }

  /*
   * イベントを解除
   */
  removeEventListener() {
    Debugger.log('---Accordion removeEventListener---');
    this.$trigger.off('click');
  }

  getHeight() {
    this.height = this.$reference.outerHeight(true);
  }

  setAnimation(ani) {
    this.$area.css({
      transition: ani
    });
  }

  toggle() {
    Debugger.log('---Accordion toggle---');
    Debugger.log('---this.isOpen ' + this.isOpen + '---');
    this.getHeight();
    const open = () => {
      this.$area.css('height', this.height);
      this.$trigger.addClass('is-active');
    };
    const close = () => {
      this.$area.css('height', 0);
      this.$trigger.removeClass('is-active');
    };

    this.isOpen
      ? //今閉じているなら開く
        open()
      : //今開いているなら閉じる
        close();
    this.isOpen = !this.isOpen;
  }

  onResize() {
    if (!this.isActive) return;
    this.isPrevPC = this.isPC;
    this.isPC = window.innerWidth >= this.BREAK_POINT;

    /*
     * ブレイクポイントが切り替わったタイミングで実行
     */
    if (this.isPrevPC !== this.isPC && this.onlySP) {
      if (this.isPC) {
        this.remove();
      } else {
        this.add();
      }
    }
  }
}

export default Accordion;
