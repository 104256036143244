'use strict';

const userAgent = require('./useragent');
const browser = userAgent.browser;

const $body = $('body');

const addBrowserClass = className => {
  $body.addClass(className);
};

const browserSelect = () => {
  if (browser.isIE || browser.isIE11) {
    addBrowserClass('browser-is-ie');
  } else if (browser.isEdge) {
    addBrowserClass('browser-is-edge');
  } else if (browser.isFireFox) {
    addBrowserClass('browser-is-ff');
  } else if (browser.isChrome) {
    addBrowserClass('browser-is-chrome');
  } else if (browser.isSafari) {
    addBrowserClass('browser-is-safari');
  }
};

module.exports = browserSelect();
