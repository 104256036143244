import Debugger from "../util/Debugger";
import overlay from "./Overlay";

const CLASS = 'is-active';

class Drawer {
  constructor($trigger, $target, $close) {
    this.$trigger = $trigger;
    this.$target = $target;
    this.$close = $close;
  }
  init() {
    this.$trigger.on('click', () => {
      this.show();
    });
    this.$close.on('click', () => {
      this.hide();
    });
    overlay.arry.push(this);
  }
  show() {
    this.$target.toggleClass(CLASS);
    overlay.show();
  }
  hide() {
    this.$target.toggleClass(CLASS);
    overlay.hide();
  }
}


const drawerMenu = () => {
  const drawer = new Drawer($('#js-drawer-trigger'), $('#js-drawer-target'), $('#js-drawer-close'));

  drawer.init();
}

export default drawerMenu();
