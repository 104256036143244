import Debugger from "../util/Debugger";

const CLASS = 'is-active';

const overlay = {
  arry: [],
  $target: $('#js-overlay'),
  init: () => {
    overlay.$target.on('click', () => {
      overlay.hide();

      for (let i = 0; i < overlay.arry.length; i++) {
        overlay.arry[i].hide();
      }
    })
  },
  show: () => {
    overlay.$target.addClass(CLASS);
  },
  hide: () => {
    overlay.$target.removeClass(CLASS);
  },
  toggle: () => {
    overlay.$target.toggleClass(CLASS);
  }
}
export default overlay;
