'use strict';

const userAgent = require('./useragent');
const device = userAgent.device;

const $body = $('body');

const addDeviceClass = className => {
  $body.addClass(className);
};

const deviceSelect = () => {
  if (device.isMobile) {
    addDeviceClass('device-is-mobile');
  }
  if (device.isIOS) {
    addDeviceClass('device-is-iphone');
  } else if (device.isIPad) {
    addDeviceClass('device-is-ipad');
  } else if (device.isAndroid) {
    addDeviceClass('device-is-android');
  } else if (device.isMac) {
    addDeviceClass('device-is-mac');
  }
};

module.exports = deviceSelect();
