import Debugger from "./Debugger";

class Screen {
  constructor(){
    this.width;
    this.height;
    this.scrollTop;

    this.frameId;

    this.resizeObjects = [];
    this.scrollObjects = [];

    this.initialize();
  }

  initialize() {
    this.setState();

    window.addEventListener('resize', (e)=>this.handleEvent(e), false);
    window.addEventListener('scroll', (e)=>this.handleEvent(e), false);
  }

  addScrollObject(target) {
    // Debugger.log('--- Screen addScrollObject ---');
		this.scrollObjects.push(target);
  }

  addResizeObject(target) {
    // Debugger.log('--- Screen addResizeObject ---');
		this.resizeObjects.push(target);
  }

  onScroll(){
    // Debugger.log('--- Screen onScroll ---');
    this.scrollTop = window.screenTop;

		for (var i = 0, ln = this.scrollObjects.length; i < ln; i++)
		{
			this.scrollObjects[i].onScroll();
		}
  }

  onResize(){
    // Debugger.log('--- Screen onResize ---');
    this.setState();

		for (var i = 0, ln = this.resizeObjects.length; i < ln; i++)
		{
			this.resizeObjects[i].onResize();
		}
  }

  setState() {
    // Debugger.log('--- Screen setBreakPoint ---');
    this.isXL = this.width >= 1400;
    this.isL = this.width < 1400 && this.width >= 1280;
    this.isM = this.width < 1280 && this.width >= 980;
    this.isS = this.width < 980 && this.width >= 768;
    this.isXS = this.width <= 767 && this.width > 640;
    this.isXXS = this.width <= 640;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }


  /*
   * requestAnimationFrameによる間引き処理
   */
  handleEvent(e){
    cancelAnimationFrame(this.frameId);
    this.frameId = requestAnimationFrame(() => {
      // Debugger.log('--- handleEvent ' + e.type + ' ---');
      switch (e.type) {
        case 'scroll':
          this.onScroll();
          break;
        case 'resize':
          this.onResize();
          break;
        default:
          break;
      }
    });
  }

}

export default new Screen;
