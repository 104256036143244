'use strict';

/*
 * utilモジュール
 */
import Debugger from './util/Debugger';
import addBrowserClass from './util/browser-class';
import addDeviceClass from './util/device-class';

/*
 * uiモジュール（Singleton）
 */
import SmoothScroll from './ui/SmoothScroll';
import Screen from './util/Screen';


/*
 * uiモジュール（要呼び出し）
 */
import Accordion from './ui/Accordion';
import ScrollChangeClass from './ui/ScrollChangeClass';
import drawerMenu from './ui/DrawerMenu';
import overlay from './ui/Overlay';
import Tab from './ui/Tab';
import Swiper from './ui/Swiper';
import Search from './ui/Search';
import PostLoader from './ui/PostLoader';

//目次の開閉処理
// const outlineAccordion = new Accordion(
//   $('#js-outline-trigger'),
//   $('#js-outline-area'),
//   $('#js-outline-reference'),
//   true
// );

// const scrollPageTop = new ScrollChangeClass(
//   $('#js-page-top'),
//   'is-active',
//   200
// );

class Main {
  constructor() {
    drawerMenu;
  }

  init() {

    //共通
    SmoothScroll.init();
    overlay.init();
    Tab.init();
    Swiper.init();
    Search.init();
    PostLoader.init();
  }
}


const main = new Main;

window.addEventListener('load', main.init);
