import Debugger from "../util/Debugger";

const CLASS = "is-active";
class Tab {
  constructor() {
    this.$container = obj.$container;
    this.$trigger = obj.$trigger;
    this.$item = obj.$item;
  }
  init() {
    this.$trigger.on('click', e => {
      this.change(e);
      return false;
    });
  }

  change(e) {
    const current = this.$trigger.index(e.currentTarget);
    Debugger.log(current);
    this.$item.removeClass(CLASS);
    this.$trigger.removeClass(CLASS);
    this.$item.eq(current).addClass(CLASS);
    this.$trigger.eq(current).addClass(CLASS);
  }
}

const obj = {
  $container: $('.js-tab'),
  $trigger: $('.js-tab-trigger'),
  $item: $('.js-tab-item'),
}

export default new Tab(obj);
