/*
 * デバッグコンソール用クラス(Singleton)
 */
class Debugger {
  // isDebug;

  // showTextInput;

  constructor() {
    this.isDebug = true;
    this.showTextInput = false;

    // if (Debugger.initialized) {
    //   return;
    // }

    if (!this.showTextInput) {
      return;
    }

    var tag = document.createElement('div');
    tag.setAttribute('id', 'CORELIB_Debugger');
    tag.style.position = 'fixed';
    tag.style.left = '0';
    tag.style.bottom = '0';
    tag.style.zIndex = '99999';
    tag.style.margin = '0';
    tag.style.padding = '5px 5px 0 5px';
    tag.style.background = 'rgba(30, 30, 30, 0.75)';
    tag.style.minWidth = '100px';

    var hideContainer = document.createElement('div');

    var w = 200;

    var createInput = () => {
      var input = document.createElement('input');
      input.setAttribute('type', 'text');
      input.style.marginBottom = '5px';
      input.style.width = w + 'px';

      return input;
    };

    var inputT1 = createInput();
    var inputT2 = createInput();
    var inputT3 = createInput();
    var inputT4 = createInput();

    var div1 = document.createElement('div');
    var div2 = document.createElement('div');
    var div3 = document.createElement('div');
    var div4 = document.createElement('div');

    var log = document.createElement('p');
    log.style.padding = '5px';
    log.style.margin = '0';
    log.style.fontSize = Debugger.fontSize;
    log.style.color = Debugger.fontColor;
    log.style['pointer-events'] = 'none';
    log.style.lineHeight = '1.5';

    tag.appendChild(hideContainer);
    hideContainer.appendChild(div1);
    hideContainer.appendChild(div2);
    hideContainer.appendChild(div3);
    hideContainer.appendChild(div4);
    div1.appendChild(inputT1);
    div2.appendChild(inputT2);
    div3.appendChild(inputT3);
    div4.appendChild(inputT4);
    //div4.appendChild(inputT4);
    hideContainer.appendChild(log);

    this.inputText1 = inputT1;
    this.inputText2 = inputT2;
    this.inputText3 = inputT3;
    this.inputText4 = inputT4;

    document.body.appendChild(tag);
  }

  log(msg) {
    if (this.isDebug) {
      console.log(msg);
    }
  }

  text(index, msg) {
    if (this.isDebug && this.showTextInput) {
      this['inputText' + index].setAttribute('value', msg);
    }
  }
}

export default new Debugger();
