import Debugger from "../util/Debugger";

const POSTS_PER_PAGE = 20;// 何件ずつ読み込むか

class PostLoader {
  constructor() {
    this.page_count = 1;
    this.offset = 0;
    this.getPosts = 0;
    this.allPosts = 0;
    this.trigger  = $('.js-get-more-posts-trigger');
    this.type = this.trigger.data('type');
    this.taxonomy = this.trigger.data('taxonomy');
    this.terms = this.trigger.data('terms');
    this.userId = this.trigger.data('author-id');
    this.string = this.trigger.data('string');
  }

  init() {
    this.getPosts = Number($('.js-get-posts').html());
    this.allPosts = Number($('.js-all-posts').html());

    // this.loading();
    this.trigger.on('click', ()=>{this.onClickMore()});
    // $(document).on('click', '.js-get-more-posts-trigger', ()=>{this.onClickMore});
  }

  loading() {
    if (this.page_count > 1) this.offset = POSTS_PER_PAGE * (this.page_count - 1);

    $.ajax({
        url: AJAX_ENDPOINT,
        type: 'POST',
        dataType: 'json',
        data: {
            action: 'get_more_posts',
            offset: this.offset,
            posts_per_page: POSTS_PER_PAGE,
            type: this.type,
            taxonomy: this.taxonomy,
            terms: this.terms,
            userId: this.userId,
            s: this.string
        },
        async: true
    }).done((data)=>{
      this.render(data);
    });
  }

  render(data) {
    Debugger.log(data);
    $('.js-articles').append(data.html);
    if (!data.last) this.trigger.show();
    this.getPosts += POSTS_PER_PAGE;
    if(this.getPosts >= this.allPosts) this.getPosts = this.allPosts;
    $('.js-get-posts').html(this.getPosts);
  }

  onClickMore() {
    Debugger.log("click");
    this.trigger.hide();
    this.page_count++;
    this.loading();
  }
}

// jQuery(function($) {
//   var run_ajax = function() {
//       if (page_count > 1) offset = POSTS_PER_PAGE * (page_count - 1);
//       $.ajax({
//           url: AJAX_ENDPOINT,
//           type: 'POST',
//           dataType: 'json',
//           data: {
//               action: 'get_more_posts',
//               offset: offset
//           },
//           async: true
//       }).done(function(data){
//           $('.p-articles').append(data.html);
//           if (!data.last) $('.js-get-more-posts-trigger').show();
//           getPosts += POSTS_PER_PAGE;
//           if(getPosts >= this.allPosts) getPosts = this.allPosts;
//           $('.js-get-posts').html(getPosts);
//       });
//   };
//   var more = function() {
//       $(this).hide();
//       page_count++;
//       run_ajax();
//   };
//   // 「さらに読み込むボタン（.more）」 をクリックしたらボタンは削除して ページ数を1ページ進め more() を実行する
//   $(document).on('click', '.js-get-more-posts-trigger', more);
//   // run_ajax();
// });

export default new PostLoader();
