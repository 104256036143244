import Debugger from "../util/Debugger";

const CLASS = 'is-current';

class _Swiper {
  constructor(obj) {
    this.$container = obj.$container;
    this.$trigger = obj.$trigger;
    this.$item = obj.$item;
    this.$scroller = obj.$scroller;
    this.itemW = this.$item.eq(0).outerWidth(true);
  }

  init() {
    Debugger.log(this.$trigger);
    this.$trigger.on('click', e => {
      this.onClick(e);
    })
  }

  onClick(e) {
    const index = this.$trigger.index(e.currentTarget);
    this.changeCurrent(index);
    this.moveTo(index);
  }

  changeCurrent(index) {
    this.$trigger.removeClass(CLASS);
    this.$trigger.eq(index).addClass(CLASS);
  }

  moveTo(index) {
    const x = this.itemW * index;
    this.$scroller.animate({
      scrollLeft: x
    }, {
      duration: 200,
      easing: 'linear',
    });
    Debugger.log(this.itemW);
  }
}

class Swiper {
  constructor() {
    this.$container = $('.js-swipe-container');
    this._instances = [];
    this._instance;
    this._obj = [];

    for (let i = 0; i < this.$container.length; i++) {

      this._obj = {
        $container: this.$container.eq(i),
        $trigger: this.$container.eq(i).find('.js-swipe-ind'),
        $item: this.$container.eq(i).find('.js-swipe-item'),
        $scroller: this.$container.eq(i).find('.js-swipe-scroller'),
      }

      this._instances.push(new _Swiper(this._obj));
    }
  }

  init() {
    for (let i = 0; i < this.$container.length; i++) {

      this._instances[i].init();
    }
  }
}


export default new Swiper;
