import Screen from "../util/Screen";

/*
 * 指定の位置までスクロールしたらクラスを付与、
 * 削除するクラス
 */
export default class ScrollChangeClass {
  constructor(elm, addClsName, point) {
    this.$pageTopElm = elm;
    this.clsName = addClsName;
    this.checkPoint = point === undefined ? 200 : point;
    this.scrollTop = $(window).scrollTop();
    this.hasCls = false;

    this.initialize();
  }

  initialize() {
    Screen.addScrollObject(this);
  }

  changeCls(state) {
    if (state === 'add') {
      this.$pageTopElm.addClass(this.clsName);
    } else if (state === 'remove') {
      this.$pageTopElm.removeClass(this.clsName);
    }
  }

  checkScrollPoint() {
    if (this.scrollTop > this.checkPoint) {
      if (!this.hasCls) {
        this.changeCls('add');
        this.hasCls = true;
      }
    } else {
      if (this.hasCls) {
        this.changeCls('remove');
        this.hasCls = false;
      }
    }
  }

  onScroll() {
    this.scrollTop = $(window).scrollTop();
    this.checkScrollPoint();
  }
}
